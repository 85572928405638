/* .paymentRadioDiv {
  background-color: blue;
} */

.paymentRadioDiv > div > div > label:has(> div > input:disabled) > div:last-child {
  opacity: 0.7;
}

.itemImg {
  border-radius: 13px;
}
